import React, { FunctionComponent } from 'react';
import { createPortal } from 'react-dom';


export type ReactPortalProps = {
  children?: React.ReactNode;
  target?: string;
}

export const ReactPortal: FunctionComponent<ReactPortalProps> = ({ children, target }) => {
  return createPortal(children, document.querySelector(target ?? 'body') as Element);
};
